import React, { useState } from 'react'

const TypeFormDateField = (props) => {
    const [birthDate, setBirthDate] = useState('')

    const handleDateChange = (e) => {
        let inputDate = e.target.value

        if (inputDate.length > 10) {
            return
        }

        inputDate = inputDate.replace(/[^\d]/g, '')

        if (inputDate.length > 2 && inputDate.length < 5) {
            inputDate = `${inputDate.substr(0, 2)}/${inputDate.substr(2)}`
        } else if (inputDate.length >= 5) {
            inputDate = `${inputDate.substr(0, 2)}/${inputDate.substr(2, 2)}/${inputDate.substr(4)}`
        }

        setBirthDate(inputDate)

        const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/

        if (dateRegex.test(inputDate)) {
            props.onError('')

            const dateArr = inputDate.split('/')
            const currentDate = new Date()
            const inputDateObject = new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`)
            const age = currentDate.getFullYear() - inputDateObject.getFullYear()

            if (age >= 15 && age < 120) {
                setBirthDate(inputDate)
                props.onChange(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`)
            } else {
                props.onError('You are too old or young')
                props.onChange(null)
            }
        } else {
            props.onError('That date isn`t valid')
            props.onChange(null)
        }
    }

    return (
        <input
            type="text"
            placeholder="dd/mm/yyyy"
            value={birthDate}
            onChange={handleDateChange}
            className="inputText"
        />
    )
}

export default TypeFormDateField