import React from 'react'
import g from '../../HomePage.module.scss'
import s from './Footer.module.scss'
import { AiOutlineInstagram, AiFillFacebook, AiFillYoutube, AiOutlineArrowRight } from 'react-icons/ai'
import { ReactComponent as Logo } from '../../../../assets/icons/LibiLogo.svg'
import { ReactComponent as AppStore } from '../../../../assets/icons/home-app-store.svg'
import { ReactComponent as PlayMarket } from '../../../../assets/icons/home-play-market.svg'

const HomeFooter = () => {
  return (
    <footer className={s.footer}>
      <div className={g.container}>
        <div className={s.footerWrapper}>
          <div>
            <h3 className={s.footerTitle}>SOCIAL NETWORKS</h3>
            <div className={s.footerSocialWrapper}>
              <div className={s.footerSocialLinks}>
                <a href="https://www.instagram.com/libi.daughters/" target='_blank'><AiOutlineInstagram /></a>
                <a href="https://www.facebook.com/libianddaughters" target='_blank'><AiFillFacebook /></a>
                <a href="https://www.youtube.com/channel/UCAVgql5mkHWKLdBb3qADeQA/featured" target='_blank'><AiFillYoutube /></a>
              </div>
              <Logo className={s.footerLogo} />
            </div>
          </div>
          <div>
            <h3 className={s.footerTitle}>INFO</h3>
            <div className={s.footerLinks}>
              <div>
                <a href="https://libi.com/policies/privacy-policy">Privacy Policy</a>
                <a href="https://libi.com/policies/terms-of-service">Term & Conditions</a>
                <a href="https://libi.com/pages/contact">Contact Us</a>
                <a href="https://libi.com/policies/refund-policy">Refund policy</a>
              </div>
              <div>
                <a href="" className={s.footerLink}>Skincare information</a>
                <a href="" className={s.footerLink}>Locate a clinic</a>
                <a href="" className={s.footerLink}>Dermatology news</a>
                <a href="" className={s.footerLink}>Professional</a>
              </div>
            </div>
          </div>
          <div>
            <form
              method="post"
              action="https://libi.com/contact#contact_form"
              id="contact_form"
              acceptCharset="UTF-8"
              className={s.footerForm}
            >
              <h3 className={s.footerFormTitle}>The Morning email</h3>
              <p className={s.footerFormText}>Wake up the day’s most important news</p>

              <input type="hidden" name="form_type" value="customer" />
              <input type="hidden" name="utf8" value="✓" />

              <div className={s.footerInputWrapper}>
                <input type="hidden" id="contact_tags" name="contact[tags]" value="prospect,newsletter" />
                <input
                  type="email"
                  name="contact[email]"
                  className={s.footerInput}
                  placeholder='Email address'
                />
                <button type="submit" className={s.footerInputBtn}>
                  <AiOutlineArrowRight />
                </button>
              </div>
            </form>
            <div className={s.footerBtns}>
              <a href="https://apps.apple.com/us/app/l-d-my-skincare/id1542599721" target='_blank' className={s.footerBtn}>
                <AppStore />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.libi.ldskincare" target='_blank' className={s.footerBtn}>
                <PlayMarket />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default HomeFooter