import React, { useState } from 'react'
import g from '../../HomePage.module.scss'
import s from './Header.module.scss'
import logo from '../../../../assets/img/header-logo.svg'
import classNames from 'classnames'

const Header = () => {
  const [open, setOpen] = useState(false)

  const toggleNav = () => {
    if (open) {
      setOpen(false)
      document.body.style.overflow = 'auto'
    } else {
      setOpen(true)
      document.body.style.overflow = 'hidden'
    }
  }

  return (
    <header>
      <div className={classNames(g.container, s.wrapper)}>
        <a href="/" className={s.logo}>
          <img src={logo} alt="Libi and Daughters" />
        </a>

        <nav className={classNames(s.nav, { [s.open]: open })}>
          <div className={s.links}>
            <a href="#" className={s.link}>Shopify</a>
            <a href="#" className={s.link}>Downlaod App</a>
            <a href="#" className={s.link}>Enter Code</a>
          </div>

          <a href="#" className={classNames(g.btn, s.btn)}>Log In</a>
        </nav>

        <button
          className={classNames(s.hamburger, { [s.active]: open })}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
    </header>
  )
}

export default Header