import React from 'react'
import './HomePage.module.scss'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Hero from './sections/Hero'
import Download from './sections/Download'
import Discover from './sections/Discover'

const HomePage = () => {
  return (
    <>
      <Header />

      <Hero />
      <Discover />
      <Download />
      
      <Footer />
    </>
  )
}

export default HomePage