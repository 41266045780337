import React from 'react'
import s from '../HomePage.module.scss'
import classNames from 'classnames'
import img1 from '../../../assets/img/hero-img-1.png'
import img2 from '../../../assets/img/hero-img-2.png'
import quote from '../../../assets/icons/quote.svg'
import {ReactComponent as TitleLines} from '../../../assets/icons/title-lines.svg'

const Hero = () => {
  return (
    <div className={s.hero}>
      <img src={img1} alt="Picture" className={s.heroImg1} />
      <img src={img2} alt="Picture" className={s.heroImg2} />

      <div className={classNames(s.container, s.heroWrapper)}>
        <h1 className={classNames(s.title, s.heroTitle)}>
          <TitleLines className={s.titleLines} />
          Login to see the product
          <TitleLines className={s.titleLines} />
        </h1>
        <p className={s.heroText}>
          <img src={quote} alt="Quote" className={s.heroQuote1} />
          We understand that everyone’s skin is different, which is why making skincare personal is the most important thing to us
          <img src={quote} alt="Quote" className={s.heroQuote2} />
        </p>
        <p className={s.heroDoctor}>
          <TitleLines className={s.heroDoctorLines} /> Dr. Inna Szalontay<br />
          London, 2020
        </p>
        <a href="#" className={classNames(s.btn, s.heroBtn)}>Shop  Now</a>
      </div>
    </div>
  )
}

export default Hero