import React, { Component } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class TypeFormPhone extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:"",
            phone: ''
        }
    }

    render() {
        return (
            <PhoneInput
                country={'gb'}
                value={this.state.phone}
                onChange={value => {
                    this.setState({phone: value})
                    this.props.onChange(value)
                }}
                countryCodeEditable={false}
                containerClass="phoneContainer"
                inputClass="phoneInput"
                buttonClass="phoneBtn"
                dropdownClass="phoneDropdown"
            />
        );
    }
}

export default TypeFormPhone;
