import React, { Component } from "react";

class LongText extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:""
        }
        this.textInput = React.createRef();
    }

    onChangeHandler = async (event) => {
        this.props.onChange(this.textInput.current.innerText)
    }

    render() {
        return (
            <div
                ref={this.textInput} 
                contentEditable="true"
                className="inputText"
                onBlur={this.onChangeHandler}
                onFocus={this.onChangeHandler}
                placeholder="Type your answer here..."></div>
                
        );
    }
}

export default LongText;
