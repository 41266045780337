import React, { Component } from "react";
import InputMask from 'react-input-mask';

class ShortText extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: '',
            error:"",
            errors:""
        }
    }

    onChangeHandler = async (event) => {
        this.setState({value: event.target.value})
        this.props.onChange(event.target.value)
    }

    render() {
        return (
            <>
                {this.props.type === 'number' &&
                    <InputMask mask="9999" className="inputText" maskChar="" onChange={this.onChangeHandler}/>
                }
                {this.props.type !== 'number' &&
                    <input
                    className="inputText"
                    type="text"
                    value={this.state.value}
                    onChange={this.onChangeHandler}
                    placeholder="Type your answer here..."/>
                }
            </>
        );
    }
}

export default ShortText;
