import React from 'react'
import s from '../HomePage.module.scss'
import classNames from 'classnames'
import img from '../../../assets/img/phones.png'
import { ReactComponent as AppStore } from '../../../assets/icons/home-app-store.svg'
import { ReactComponent as PlayMarket } from '../../../assets/icons/home-play-market.svg'
import {ReactComponent as TitleLines} from '../../../assets/icons/title-lines.svg'

const Download = () => {
  return (
    <div className={s.download}>
      <div className={classNames(s.container, s.downloadWrapper)}>
        <div>
          <img src={img} alt="Phones" />
        </div>
        
        <div className={s.downloadMain}>
          <h2 className={classNames(s.title, s.downloadTitle)}>
            Beauty Elevated: Download Now!
            <TitleLines className={s.titleLines} />  
          </h2>
          <p className={s.text}>Experience a new level of beauty indulgence with our Luxe Cosmetic App. Discover a world of elegance and sophistication at your fingertips. Download our app today and unlock a seamless beauty journey tailored to your unique style.</p>
          <div className={s.downloadBtns}>
            <a href="https://apps.apple.com/us/app/l-d-my-skincare/id1542599721" target='_blank' className={classNames(s.btn, s.downloadBtn)}>
              <AppStore />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.libi.ldskincare" target='_blank' className={classNames(s.btn, s.downloadBtn)}>
              <PlayMarket />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Download