import React from 'react'
import s from '../HomePage.module.scss'
import { ReactComponent as TitleLines } from '../../../assets/icons/title-lines.svg'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from "swiper";
import 'swiper/css';
import 'swiper/css/effect-fade';
import img1 from '../../../assets/img/discover-1.png'
import img2 from '../../../assets/img/discover-2.png'
import { isMobile } from 'react-device-detect'

const data = [img1, img2]

const Discover = () => {
  const pagination = {
    clickable: true,
    renderBullet: (index, className) => `<span class="${className}" /></span>`
  }

  return (
    <div className={s.discover}>
      <div className={classNames(s.container, s.discoverWrapper)}>
        <div className={s.discoverMain}>
          <h2 className={classNames(s.title, s.discoverTitle)}>
            Discover Luxe Cosmetics: Scan Now!
            <TitleLines className={s.titleLines} />
          </h2>
          <p className={s.text}>Experience a seamless blend of technology and opulence with our innovative QR code scanning feature. Prepare to embark on a captivating journey as you delve into the realm of luxury cosmetics</p>
          <div className={s.discoverBtns}>
            {isMobile && 
              <label className={s.btn}>
                Scan now
                <input type="file" accept="image/*" capture="environment" hidden className={s.scanInput} />
              </label>
            }
            <a href="#" className={s.discoverLink}>Enter code manually</a>
          </div>
        </div>
        <div className={s.discoverSlider}>
          <Swiper
            pagination={pagination}
            modules={[Pagination, EffectFade]}
            slidesPerView={1}
            effect='fade'
          >
            {data.map((i, index) => (
              <SwiperSlide key={index}>
                <img src={i} alt="Picture" className={s.discoverSlideImg} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Discover