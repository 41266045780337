import { Route, Routes } from 'react-router-dom'
import './index.css'
import HomePage from './pages/Home/HomePage';
import TypeFormPage from './pages/TypeForm/TypeFormPage';
import UploadPhotos from './pages/TypeForm/components/UploadPhotos';

function App() {
  return (
    <Routes>
      <Route path={'/'} element={<HomePage />} />
      <Route path={'/questionnaire'} element={<TypeFormPage />} />
      <Route path={'/upload-photos/:userId'} element={<UploadPhotos />} />
    </Routes>
  );
}

export default App;
