import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import api from "../../../api";
import logo from "../../../assets/img/logo.png";
import { storage } from "../../../firebase";
import "../TypeForm.scss";
import FileUpload from "./FileUpload";

const imgs = [
  "https://images.typeform.com/images/m4Gc3uKmuWLy",
  "https://images.typeform.com/images/yBKwPBv35AUd",
  "https://images.typeform.com/images/VfuHi8mfEBG8",
  "https://images.typeform.com/images/WkkLe6kzznAa",
  "https://images.typeform.com/images/WkkLe6kzznAa",
];

const titles = [
  "1. Please take a photo of your nose, mouth and chin like in the example*",
  "2. Please take a photo, focusing on your left cheek, as  inducated in the photo*",
  "3. Please take a photo focusing on your right cheek, as shows in the picture*",
  "4. Please include a photo of your forehead*",
  "Thank you!",
];

const UploadPhotos = () => {
  const userId = window.location.href.split("/").pop();

  const [currentView, setCurrentView] = useState(0);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");

  const changeHandler = (file, type) => {
    const fileName = file.name.split(".")[0];
    const fileExtension = file.name.split(".")[1];
    const uuid = uuidv4();

    const newFileName = `${fileName}-${uuid}.${fileExtension}`;

    const renamedFile = new File([file], newFileName, { type: file.type });
    setFile(renamedFile);
  };

  const onSubmit = async () => {
    if (currentView === titles.length - 1) {
      window.location.href = "/";
      return;
    }

    setIsUploading(true);

    try {
      const fileRef = storage.child(`attachments/${file.name}`);

      await fileRef
        .put(file, {
          contentType: file.type,
        })
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then(async (url) => {
          const fileObj = {
            name: file.name,
            url,
            time: new Date(),
          };

          await api
            .post("createAttachment", {
              user_id: userId,
              ...fileObj,
            })
            .then(() => {
              setError("");
              setFile(null);
              setCurrentView((prev) => prev + 1);
            });
        });
    } catch (err) {
      console.log("err", err);
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  const redirectToPrevPhoto = () => {
    setCurrentView((prev) => prev - 1);
  }

  return (
    <div
      className="UploadPhotosWrapper"
      style={{ "--answer-color": "#92001A" }}
    >
      <div className="UploadPhotosMain">
        <a href="/" className="UploadPhotosLogo">
          <img src={logo} alt="Libi&Daughters" />
        </a>

        <p>{titles[currentView]}</p>

        {currentView < titles.length - 1 && (
          <div className="UploadPhotosInput">
            <FileUpload
              key={currentView}
              onChange={changeHandler}
              onError={setError}
            />
          </div>
        )}

        {error && <div className="UploadPhotosError">{error}</div>}

        <button
          type="button"
          className="typeFormWelcomeButton UploadPhotosButton"
          onClick={onSubmit}
          disabled={isUploading}
        >
          {isUploading ? "Uploading..." : "Continue"}
        </button>
        {currentView > 0 && (
          <button
            type="button"
            onClick={redirectToPrevPhoto}
            className="typeFormWelcomeBackButton"
          >
            Back
          </button>
        )}
      </div>
      <img className="UploadPhotosImg" src={imgs[currentView]} alt="Face" />
    </div>
  );
};

export default UploadPhotos;
